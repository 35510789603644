@tailwind base;
@tailwind components;
@tailwind utilities;


*{
  font-family: "Rajdhani";
}

.page-container{
  margin: 5rem 5rem 0 5rem;
}

@media (max-width: 768px) {
  .page-container {
    margin: 5rem 3rem 0 3rem;
  }
}

.textWrapper {
  flex: 1; 
  font-size: 24px;
  font-weight: 300;
  text-align: left;
}

@media (min-width: 769px){
  .textWrapper{
    padding-left: 124px;
  }
}

@media (max-width: 768px) {
  .textWrapper {
    font-size: 18px;
    text-align: center;
    border-top: 1px solid #eee;
    padding-top: 40px;
  }
}
.imageWrapper {
  position: relative;
  margin-right: 20px;
  max-width: 130px;
}

@media (max-width: 768px) {
  .imageWrapper {
    display: none;
  }
}