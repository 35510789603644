.timelineContainer {
  display: flex; 
  align-items: center; 
}
.timelineContainer:not(:first-child){
  margin-top: 60px;
}
@media (max-width: 1280px){
  .timelineContainer:first-child{
    margin-top: 60px;
  }
}

.logo {
  display: inline-block;
  vertical-align: middle;
}

.logoBorder {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  border: 1px solid #c6c6c6;
  transform: rotate(45deg);
}

.logoBorder:hover {
  border-style: dashed;
  border-color: #121212;
}

.line {
  position: absolute;
  width: 2px;
  height: 180%;
  background-color: #c6c6c6;
  top: 0;
  right: -80px;
}

.pointer {
  position: absolute;
  width: 14px;
  height: 14px;
  background-color: #333;
  top: 50%;
  right: -81px;
  transform: rotate(45deg) translateY(-50%);
}



.primaryTitle {
  font-weight: 600;
  margin-bottom: 1px;
  word-break: break-word;
}

.secondaryTitle {
  color: #121212;
  font-weight: 500;
  text-decoration: underline;
}

.secondaryTitle:hover {
  color: #333;
  text-decoration: underline;
}

.table {
  display: table;
  margin: 0 auto;
}
