.bm-burger-button {
  position: absolute;
  width: 60px;
  height: 40px;
  left: 2.5rem;
  top: 2rem;
  transition: white 0.3s;

}

.bm-burger-bars {
  background: black;
  transition: white 0.3s;
}

.bm-burger-bars-hover {
  background: white;
  transition: 0.3s;
}

.bm-cross-button{
  width: 35px !important;
  height: 35px !important;
  margin-top: 15px;
  margin-right: 25px;
}

.bm-cross {
  background: white;
  width: 7px !important;
  height: 32px !important;
}

.bm-menu-wrap {
  position: fixed;
  height: 100%;
  top: 0;
  width: 100% !important
}

.bm-menu {
  background: black;
  padding: 2.5em 1.5em 0;
  font-size: 2rem;
  transition: white 0.3s;
}

.bm-morph-shape {
  fill: black;
}

.bm-item-list {
  color: white;
  padding: 0;
}

.bm-item {
  color: white;
  margin-bottom: 10px;
  text-align: left;
  text-decoration: none;
  transition: white 0.2s;
}

.bm-item:hover {
  background-color: white !important;
  color: black !important;
}

.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
  transition: white 0.3s;
}
.bm-item.active{
  color: grey;
}

@media (min-width: 768px) {
  .bm-menu-wrap {
    width: 500px !important;
  }

  .bm-cross-button .bm-cross{
    display: none
  }
  
}